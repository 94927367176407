import React, { useEffect } from 'react'
import { Button } from 'reactstrap';
import logo from './logo.svg';
import { asyncComponent } from './components/AsyncLoader'
import PlanningService from './components/Services/planning';
import { Flash } from './components/Layout/Shared/FlashMessage';
import Bus from './components/Layout/Shared/Bus';

// Globala konstanter att jämföra med
global.APP_BUILD_DEV = "dev";
global.APP_BUILD_TEST = "test";
global.APP_BUILD_PROD = "prod";

// Ändra dessa vid nya versioner/byggen
global.appVersion = "1.30";
global.appBuild = "test";

type Props = {
  translation: any,
  fetchMessages: any
}

const App = (props: Props) => {

  const AsyncRoutes = asyncComponent(() => import("./components/Routes"))

  const init = () => {
    //props.fetchMessages(intlDefaultLocale)
  }
  window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));
  //useEffect(() => init(), []);

  return (
    //<IntlProvider  locale={props.translation.get('cultureId', intlDefaultLocale)} messages={props.translation.get('messages').toJS()} >
    <div>
      <Flash />
      <AsyncRoutes></AsyncRoutes>
    </div>
  );
}

export default App;
